import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiAddFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { data } from "../../../components/Product Form/_data";
import { TableContent } from "../../../components/Table/TableContent";
import { TablePagination } from "../../../components/Table/TablePagination";
import Blogs from "../../../components/UI/Blogs";
import Empty from "../../../components/UI/Empty";
import { viewblogs } from "../../../services/blog";
import moment from "moment";
import { getCategories } from "../../../services/category";
import { viewProducts } from "../../../services/products";




export const columns = [
  {
    Header: "Image",
    accessor: "image",
    Cell: function BlogCell(data: any) {
      return <Blogs title={data.title} feature_image={data.feature_image} />;
    },
  },
  {
    Header: "Title",
    Cell: function ProductCell(data: any) {
      return <Text>{data.title}</Text>;
    },
  },
  {
    Header: "Date",
    Cell: function ProductCell(data: any) { 
    
      return <Text>{moment(data.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Text>;
    },
  },
  {
    Header: "Actions",
    accessor: "product",
    Cell: function ProductCell(data: any) {
      return (
        <Link to={"/blog/" + data.slug}>
          <Button variant="link" colorScheme="blue">
            Edit
          </Button>
          
          {/*
          <Button variant="link" colorScheme="blue">
            View
          </Button> */}
        </Link>
         
      );
    },
  },
];

const TableActions = (props: any) => {
  const categories = getCategories();

  return (
    <Stack
      spacing="4"
      direction={{ base: "column", md: "row" }}
      justify="space-between"
    >
      <HStack></HStack>
      <ButtonGroup size="sm" variant="outline">
        <Link to="/news/create-news">
          <Button iconSpacing="1" leftIcon={<RiAddFill fontSize="1.25em" />}>
            Add News
          </Button>
        </Link>
      </ButtonGroup>
    </Stack>
  );
};

export default function News() {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState({ page: 1,type :  location.pathname.includes("blog")?"blog":"news" });
  const [empty, setEmpty] = useState(false);
  const [firstLoad, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
   

  useEffect(() => {
    if (firstLoad) setLoading(true);
    viewblogs(query)
      .then((res: any) => {
        setProducts(res.data.blogs);
         
        setTotal(res.data.total);
        if (res.data.total === 0 && firstLoad) setEmpty(true);
        setLoad(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [query]);


 
  return (
    // <ProductTable query={query} onQueryChange={(_q: any) => {setQuery(_q);}} total={total} items={products}/>

    <Box as="section" py="12">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Box overflowX="auto">
          <Heading size="lg" mb="6">
            News
          </Heading>
          {empty ? (
            <Empty
              title="Add a News and Get Started"
              text={
                <span>
                  Add your News manually via the dashboard. News must have at
                  least one image and basic details. Learn more.
                </span>
              }
              action={
                <Link to="/news/create-news">
                  <Button colorScheme={"blue"} leftIcon={<BiPlus />}>
                    Add Blog
                  </Button>
                </Link>
              }
            />
          ) : (
            <>
              <TableActions
                query={query}
                onChange={(v: any) => {
                  setQuery(v);
                }}
              />
              {loading ? (
                <VStack py={20} width={"100%"}>
                  <Spinner></Spinner>
                </VStack>
              ) : (
                <TableContent items={products} columns={columns} />
              )}
              <TablePagination
                onNext={() => setQuery({ ...query, page: query.page + 1 })}
                onPrevious={() => setQuery({ ...query, page: query.page - 1 })}
                total={total}
                type={"product"}
                query={query}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
