import { Box, Img, Stack } from "@chakra-ui/react";
import { AWS_URL } from "../../enviroments/enviroments";

interface BlogsProps {
  title: string;
  feature_image: string;
}

export default (props: BlogsProps) => {
  const { title, feature_image } = props;
  return (
    <Stack direction="row" spacing="4" align="center">
      <Box flexShrink={0} h="20" w="20">
        <Img
          objectFit="cover"
          htmlWidth="160px"
          htmlHeight="160px"
          rounded="lg"
          src={
            feature_image.includes("https://")
              ? feature_image
              : AWS_URL + feature_image
          }
          alt=""
        />
      </Box>
    </Stack>
  );
};
