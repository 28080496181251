import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Select,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { FaPlus } from "react-icons/fa";
import { FieldGroup } from "../Form Layouts/FieldGroup";

import BlogList from "../Blog Form/BlogList";
import { deleteProduct, editProduct } from "../../services/products";
import validate from "../../services/validator";
import VariantForm from "../VariantForm/VariantForm";
import Confirmation from "../Form Layouts/Confirmation";
import { useNavigate, useParams } from "react-router-dom";
import Editor from "../EditorJS/EditorJS";
import ArrayInput from "../ArrayInput/ArrayInput";
import { title } from "process";

let schema = [
  {
    name: "title",
    type: "text",
    required: true,
    rules: {
      min: 1,
      max: 50,
    },
  },
  {
    name: "slug",
    type: "pattern",
    required: true,
    rules: {
      pattern: `^[a-zA-Z0-9-]+$`,
      message:
        "Slug must no contain any special characters (instead of hyphens) or spaces.",
    },
  },
  {
    name: "description",
    type: "object",
    required: true,
  },
  {
    name: "more_image",
    type: "list",
    required: true,
    rules: {
      min: 1,
    },
  },
];

let edit_schema = [
  {
    name: "title",
    type: "text",
    required: true,
    rules: {
      min: 1,
      max: 50,
    },
  },
  {
    name: "slug",
    type: "pattern",
    required: true,
    rules: {
      pattern: `^[a-zA-Z0-9-]+$`,
      message:
        "Slug must no contain any special characters (instead of hyphens) or spaces.",
    },
  },
  {
    name: "description",
    type: "object",
    required: true,
  },
  {
    name: "more_image",
    type: "list",
    required: true,
    rules: {
      min: 1,
    },
  },
];

export const BlogForm = (props: any) => {
  var product = props.formData;

  const [title, setName] = React.useState(product?.title);
  const [slug, setSlug] = React.useState(product?.slug);
  const [description, setDesc] = React.useState(
    product?.description === ""
      ? {
          blocks: [
            { type: "paragraph", data: { text: "Start writing here..." } },
          ],
        }
      : product?.description
  );
  const [type, setType] = React.useState(product?.product_type || "");
  const [more_image, setMedia]: any = React.useState(product?.more_image || []);
  const [feature_image, setFeature_image]: any = React.useState(
    product?.feature_image || []
  );
  const [is_active, setIsactive]: any = React.useState(
    product?.is_active || []
  );
  const [tags, setTags]: any = React.useState(product?.tags || []);
  const [errors, setErrors]: any = React.useState({});
  const [variants, setVariants] = React.useState(product?.variants);

  const toast = useToast();
  let navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    const data = {
      title,
      slug,
      description,
      more_image,
      feature_image,
      is_active,      
    };

    validate(props.create ? schema : edit_schema, data).then((result) => {
      props.onChange(data, result.valid, false);
      if (result?.valid === false) {
        setErrors(result.errors);
      }
    });
  }, [title, slug, description, more_image, feature_image,is_active,type]);

  const onSave = (data: any) => {
    editProduct({ variants: data }, `${id}`);
  };

  const removeProduct = () => {
    deleteProduct(`${id}`).then((result: any) => {
      navigate("/products");
      toast({
        title: result.message,
        status: "success",
        position: "top-right",
        isClosable: true,
        duration: 5000,
      });
    });
  };
   const currenturl = window.location.pathname == '/news/create-news';
   
  

  return (
    <Grid columnGap={8} templateColumns={{ lg: "1fr 1fr", xl: "1fr 420px" }}>
      <Stack spacing="4">
        <FieldGroup title={currenturl?"News":"Blog"}>
          <VStack width="full" spacing="6">
            <FormControl
              isInvalid={errors?.title && props.showErrors}
              isRequired
              id="title"
            >
              <FormLabel>Title</FormLabel>
              <Input
                value={title}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                maxLength={255}
              />
              {props.showErrors && (
                <FormErrorMessage textTransform={"capitalize"}>
                  {errors?.title?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={errors?.slug && props.showErrors}
              isRequired
              id="slug"
            >
              <FormLabel>Slug</FormLabel>
              <Input
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                type="text"
              />
              {props.showErrors && (
                <FormErrorMessage textTransform={"capitalize"}>
                  {errors?.slug?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={errors?.description && props.showErrors}
              isRequired
              id="description"
            >
              <FormLabel>Description</FormLabel>
              <Editor
                value={description}
                onChange={(v: any) => {
                  setDesc(v);
                }}
              />
              {!props.showErrors ? (
                <FormHelperText>
                  Controlled by EditorJS. See how to write description here.
                </FormHelperText>
              ) : (
                <FormErrorMessage textTransform={"capitalize"}>
                  {errors?.description?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            {/* <FormLabel>Status</FormLabel>
            <Select name="is_active" value={is_active}   onChange={(e) => {
                  setIsactive(e.target.value);
                }}>           
            <option value='true'>Active</option>
            <option value='false'>In Active</option>
             
          </Select> */}
          </VStack>
        </FieldGroup>

        {/*size chart  */}
        <BlogList
          title={"Image"}
          multiple={false}
          canSelect={!props.create}
          media={feature_image}
          onChange={(m: any) => {
            setFeature_image(m);
          }}
          error={
            props.showErrors && feature_image.length < 1
              ? "Atleast one feature_image is required."
              : false
          }
        />
        <BlogList
          canSelect={!props.create}
          multiple={true}
          media={more_image}
          onChange={(m: any) => {
            setMedia(m);
          }}
          error={
            props.showErrors && more_image.length < 1
              ? "Atleast one image is required."
              : false
          }
        />

        {/* <FieldGroup title="Notifications">
            <Stack width="full" spacing="4">
              <Checkbox>Get updates about the latest meetups.</Checkbox>
              <Checkbox>Get notifications about your account activities</Checkbox>
            </Stack>
          </FieldGroup>
          <FieldGroup title="Connect accounts">
            <HStack width="full">
              <Button variant="outline" leftIcon={<FaGithub />}>
                Connect Github
              </Button>
              <Button variant="outline" leftIcon={<Box as={FaGoogle} color="red.400" />}>
                Connect Google
              </Button>
            </HStack>
          </FieldGroup> */}
      </Stack>
    </Grid>
  );
};
