import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  useToast,
} from "@chakra-ui/react";

import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { BlogForm } from "../../../components/Blog Form/BlogForm";
import { createBlog as create } from "../../../services/blog";

export default function CreateBlog() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showErrors, setErrors] = useState(false);
  const [validity, setValidity] = useState(false);
  const location = useLocation();
  const toast = useToast();
  let navigate = useNavigate();

  const handleChange = (payload: any, valid: any) => {
    setData(payload);
    setValidity(valid);
  };
  
  const CreateNews = (payload: any) => {


    setErrors(!validity);
    if (validity) {
      if(location.pathname.includes("blogs")){
        payload.type = "blog"
      }else{
        payload.type = "news"
      }
      setLoading(true);
      create(payload)
        .then((result: any) => {
          toast({
            title: result.message,
            status: "success",
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
          setLoading(false);
          navigate("/news");
        })
        .catch((err) => {
          toast({
            title: err.message,
            status: "error",
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
          setLoading(false);
        });
    } else
      toast({
        title: "Please fill the required fields.",
        status: "error",
        position: "top-right",
        isClosable: true,
        duration: 5000,
      });
  };

  return (
    <Box as="section" py="12">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <HStack align={"center"} justify="space-between" mb="10">
          <HStack>
            <Link to="/products">
              <IconButton
                aria-label="back-to-products"
                variant={"ghost"}
                icon={<FaArrowLeft />}
              ></IconButton>
            </Link>
            <Heading size="lg">Create News</Heading>
          </HStack>
          <ButtonGroup>
            <Button
              isLoading={loading}
              loadingText="Loading"
              onClick={(e: any) => {
                CreateNews(data);
              }}
              colorScheme={"blue"}
            >
              Create
            </Button>
            <Link to={"/news"}>
              <Button variant={"outline"} colorScheme={"gray"}>
                Discard
              </Button>
            </Link>
          </ButtonGroup>
        </HStack>
        <BlogForm showErrors={showErrors} create onChange={handleChange} />
      </Box>
    </Box>
  );
}
