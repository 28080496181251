import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  useToast,
} from "@chakra-ui/react";

import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { BlogForm } from "../../../components/Blog Form/BlogForm";
import {
  createBlog as create,
  UpdateBlogs,
  viewblog,
  viewblogs,
} from "../../../services/blog";

export default function BlogSlug() {
  const [data, setData] = useState<any>();
  const [blog, setBlog] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showErrors, setErrors] = useState(false);
  const [validity, setValidity] = useState(false);

  const toast = useToast();
  const { slug }: any = useParams();

  let navigate = useNavigate();
  useEffect(() => {
    viewblog(slug)
      .then(({ data }: any) => {
        setBlog(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleChange = (payload: any, valid: any) => {
    setData(payload);
    setValidity(valid);
  };

  const UpdateBlog = (payload: any) => {
    setErrors(!validity);
    if (validity) {
      setLoading(true);
      UpdateBlogs(payload, blog._id)
        .then((result: any) => {
          toast({
            title: result.message,
            status: "success",
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
          setLoading(false);
          navigate("/blog");
        })
        .catch((err) => {
          toast({
            title: err.message,
            status: "error",
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
          setLoading(false);
        });
    } else
      toast({
        title: "Please fill the required fields.",
        status: "error",
        position: "top-right",
        isClosable: true,
        duration: 5000,
      });
  };

  return (
    <Box as="section" py="12">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <HStack align={"center"} justify="space-between" mb="10">
          <HStack>
            <Link to="/products">
              <IconButton
                aria-label="back-to-products"
                variant={"ghost"}
                icon={<FaArrowLeft />}
              ></IconButton>
            </Link>
            <Heading size="lg">{data?.title}</Heading>
          </HStack>
          <ButtonGroup>
            <Button
              isLoading={loading}
              loadingText="Loading"
              onClick={(e: any) => {
                UpdateBlog(data);
              }}
              colorScheme={"blue"}
            >
              Update
            </Button>
            <Link to={"/blog"}>
              <Button variant={"outline"} colorScheme={"gray"}>
                Discard
              </Button>
            </Link>
          </ButtonGroup>
        </HStack>
        {blog && (
          <BlogForm
            showErrors={showErrors}
            formData={blog}
            onChange={handleChange}
          />
        )}
      </Box>
    </Box>
  );
}
